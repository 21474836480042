<template>
    <template v-if="!showLowerTimeline">
        <div class="parent timeline-column timeline-column-scrollable o365-header-cell o365-header-cell-container" style="cursor: default; position: absolute; top: 0; left: 0px; padding: 0 !important; width: 100%; height: calc(100%); z-index: 10; overflow: hidden" >
            <div style="position: absolute; top: 6px; right: 29px; z-index: 111; cursor: pointer;" >
                <ODropdown placement="bottom-end">
                    <template #default="{target, open}">
                        <span :ref="target" @click="open" class="timeline-header-dropdown" style="background-color: white; border: 1px solid gray; border-radius: 3px; padding: 2px 6px;">
                            <i class="bi bi-info-circle"></i>
                        </span>
                    </template>
                    <template #dropdown="scope">
                        <div :ref="scope.container">
                            <div class="card dropdown-menu shadow user-select-none" style="width: 400px;">

                                <div v-for="barType in barTypesArray" :key="barType.name" class="d-inline-flex justify-content-between mt-1">
                                    <div class="d-inline-flex justify-content-between mt-1">
                                        <div class="ms-3 form-check">
                                            <input class="form-check-input" id="toggleActive" type="checkbox" v-model="barType.active" @change="handleToggleActive(barType)">
                                        </div>
                                        <span style="white-space: pre;">{{ barType.name }} </span>

                                        <div v-if="barType.pointShape?.label || barType.border?.label || barType.summary?.label" >
                                            <i class="bi bi-chevron-down ms-2" style="vertical-align: middle; cursor:pointer; position:relative; z-index: 101;" v-if="!showAdditionalBars" @click="showAdditional(barType)"></i>
                                            <i class="bi bi-chevron-up ms-2" style="vertical-align: middle; cursor:pointer; position:relative; z-index: 101;" v-if="showAdditionalBars" @click="hideAdditional(barType)"></i>

                                            <div style="max-height:200px;overflow:auto;max-width:1500px" v-if="showAdditionalBars || barType.labelExpanded">
                                                <div v-for="addBars in barType" :key="barType.name" class="d-inline-flex justify-content-between mt-1" >
                                                    <span style="white-space: pre;" v-if="addBars.label">{{ addBars.label }}</span>
                                                    <div style=" width:200px;" v-if="addBars.color">
                                                        <svg style="background-color: transparent;height:20px; position:absolute; right: 0;" >
                                                            <polygon class="float-end" :points="getBarPoints(addBars)" :fill="getFillColor(addBars)" :stroke="getOutlineColor(addBars)" style="stroke-linejoin: round; z-index: 100; stroke-width: 0.75"/>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div style=" width:200px;">
                                            <svg v-if="barType.shape" style="background-color: transparent;height:20px; position:absolute; right: 0;">
                                                <polygon class="float-end" v-if="barType.shape == 'progress'" points="150 0 280 0 280 15 150 15 150 0" fill='#8ee997' style="stroke-linejoin: round; z-index: 100; stroke-width: 0.75"/>
                                                <polygon class="float-end" :points="getBarPoints(barType)" :fill="getFillColor(barType)" :stroke="getOutlineColor(barType)" style="stroke-linejoin: round; z-index: 100; stroke-width: 0.75"/>
                                            </svg>
                                            <i class="bi" :class="barType.icon.class, barType.icon.color" v-if="barType.icon" style="background-color: transparent;height:20px; position:absolute; right: 0; margin-right: 20px;"></i>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </ODropdown>
                <ODropdown placement="bottom-end" >
                    <template #default="{target, open}">
                        <span :ref="target" @click="open" class="timeline-header-dropdown" style="background-color: white; border: 1px solid gray; border-radius: 3px; padding: 2px 6px;">
                            <i class="bi bi-zoom-in"></i>
                        </span>
                    </template>
                    <template #dropdown="scope">
                        <div :ref="scope.container">
                            <div class="card dropdown-menu shadow" style="width: 460px;" :style="{ opacity: dimZoomMenu ? '0.8' : '1' }">
                                <div :style="{ opacity: dimZoomMenu ? '0' : '1' }">
                                    <button class="dropdown-item" @click=" emitPanMode($event); scope.close();">
                                        <i class="bi bi-arrows-move me-1"></i>
                                        Pan Mode
                                        <label class="float-end" title="To navigate the timeline more efficiently: While hovering the cursor over the timeline, hold down the left mouse button and move the cursor around.">
                                            <i class="bi bi-info-circle me-1"></i>
                                            Left Mouse Botton + Move
                                        </label>
                                    </button>
                                </div>
                                <div class="dropdown-divider" :style="{ opacity: dimZoomMenu ? '0' : '1' }"></div>
                                <div class="px-3 mb-2" style="position: relative;">
                                    <div :style="{ opacity: dimZoomMenu ? '0' : '1' }">
                                        <label class="mb-1">
                                            {{ $t('Scroll To') }}
                                        </label>
                                        <label class="mb-1 float-end" title="To scroll the timeline more efficiently: While hovering the cursor over the timeline, hold down the Shift key on the keyboard and scroll the mouse wheel.">
                                            <i class="bi bi-info-circle me-1"></i>
                                            Shift + Mouse Wheel
                                        </label>
                                    </div>
                                    <input type="range" style="width: 420px;" :style="{background: 'steelblue'}" :min="outerFrom?.getTime()" :max="outerTo?.getTime()" :value="from?.getTime() + (to?.getTime() - from?.getTime())/2" @input="emitScrollTimelineToEvent($event)" @mousedown="dimZoomMenu=true" @mouseup="dimZoomMenu=false">
                                </div>
                                <div :style="{ opacity: dimZoomMenu ? '0' : '1' }">
                                    <div class="dropdown-divider"></div>
                                    <button class="dropdown-item" @click="$emit('zoomTimeline', 'in');">
                                        <i class="bi bi-plus-square me-1"></i>
                                        Zoom In
                                        <label class="float-end" title="To zoom the timeline more efficiently: While hovering the cursor over the timeline, hold down the Ctrl key on the keyboard and scroll the mouse wheel.">
                                            <i class="bi bi-info-circle me-1"></i>
                                            Ctrl + Mouse Wheel
                                        </label>
                                    </button>
                                    <button class="dropdown-item" @click="$emit('zoomTimeline', 'out');">
                                        <i class="bi bi-dash-square me-1"></i>
                                        Zoom Out
                                    </button>
                                    <div class="dropdown-divider"></div>
                                    <div class="px-3 mb-2" style="position: relative;">
                                        <label class="mb-1">
                                            {{ $t('Zoom To') }}
                                        </label>
                                        <br>
                                        <div class="btn-group" role="group" aria-label="Basic example">
                                            <button class="btn btn-outline-secondary" @click.stop="$emit('setZoom', 'year'); scope.close()" @mouseover="() => $emit('setZoom', 'year', true)" @mouseleave="() => $emit('resetZoomPreview')">Year</button>
                                            <button class="btn btn-outline-secondary" @click="$emit('setZoom', 'month'); scope.close()" @mouseover="() => $emit('setZoom', 'month', true)" @mouseleave="() => $emit('resetZoomPreview')">Month</button>
                                            <button class="btn btn-outline-secondary" @click="$emit('setZoom', 'week'); scope.close()" @mouseover="() => $emit('setZoom', 'week', true)" @mouseleave="() => $emit('resetZoomPreview')">Week</button>
                                            <button class="btn btn-outline-secondary" @click="$emit('setZoom', 'day'); scope.close()" @mouseover="() => $emit('setZoom', 'day', true)" @mouseleave="() => $emit('resetZoomPreview')">Day</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </ODropdown>
                <Slider />
                <ODropdown placement="bottom-end" v-if="isPinned">
                    <template #default>
                        <span @click="unPin" class="timeline-header-dropdown" style="background-color: white; border: 1px solid gray; border-radius: 3px; padding: 2px 6px;">
                            <i class="bi bi-pin-fill"></i>
                        </span>
                    </template>
                </ODropdown>
            </div>
            <div style="width: 100%; height: 100%; overflow: hidden;"> 
                <div style="position: relative; height: 100%; width: 100%; display: flex; flex-direction: row; align-items: center;">
                    <template v-for="timeline in upper_timeline">
                        <div :style="'position: absolute; width: ' + timeline.width + 'px; transform: translateX(' + timeline.offset + 'px);' + ' overflow: hidden; height: 100%; ' + (!timeline.is_first ? 'border-left: 2px solid var(--o365-grid-header-border);' : '')">
                            <div style="width: 100%; height: 100%; display: flex; flex-direction: row; align-items: center; justify-content: center; color: #28292e;">
                                {{date_text(timeline, TextType.Full)}}
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </template>
    <template v-if="showLowerTimeline">
        <div style="cursor: default; position: absolute; top: 0; left: 0px; padding: 0 !important; width: 100%; height: calc(100%); z-index: 10; overflow: hidden; white-space: nowrap;">
            <div style="position: relative; height: 100%; width: 100%; display: flex; flex-direction: row; align-items: center;" >
                <template v-for="timeline in lower_timeline" >
                    <div :style="'position: absolute; width: ' + timeline.width + 'px; transform: translateX(' + timeline.offset + 'px);' + ' overflow: hidden; height: 100%; ' + (!timeline.is_first ? 'border-left: 2px solid var(--o365-grid-header-border);' : '')">
                        <div style="width: 100%; height: 100%; display: flex; flex-direction: row; align-items: center; justify-content: center; color: #28292e;">
                            {{ date_text_lower(timeline, TextType.Simple )}}
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </template>
</template>


<script setup lang="ts">
    import { computed, defineEmits, ref, watch, onMounted } from 'vue';
    import Slider from 'o365.vue.components.DataGrid.Header.DateSlider.vue';

    const props = defineProps({
        from: Date,
        to: Date,
        outerFrom: Date,
        outerTo: Date,
        width: Number,
        hasReachedMax: Boolean,
        barTypes: Object,
        showLower: Boolean,
        isPinned: String
    });

    const emit = defineEmits(['scrollTimelineTo', 'setZoom', 'resetZoomPreview', 'toggle-active', 'enter-pan-mode', 'zoomTimeline', 'toggle-pinned'])

    const showAdditionalBars = ref(true);
    const isHovering = ref(false);

    const isPinned = ref(props.isPinned);
    const isPinnedd = ref(false)

    const showLowerTimeline = ref(props.showLower ? true : false);

     watch(() => props.isPinned, (newValue) => {
        isPinned.value = newValue;
        console.log('Pinned', newValue)
    });

    function showAdditional(bar){
        showAdditionalBars.value=true;
        bar.labelExpanded = true;
    }

    function hideAdditional(bar){
        showAdditionalBars.value=false;
        bar.labelExpanded = false;
    }

    function setHovering() {
        isHovering.value =! isHovering.value
    }

    const unPin = () => {
        isPinnedd.value = !isPinnedd.value;
        emit('toggle-pinned', isPinnedd.value);
    }

    const barTypesArray = computed(() => {
        const newArray = Array.from(props.barTypes?.values());
        return newArray;
    });

    function handleToggleActive(bar) {
        emit('toggle-active', bar);
        console.log(bar.name + ': ' + bar.active);
    }

    function emitScrollTimelineToEvent(pEvent) {
        const newDate = new Date(parseInt(pEvent.target.value));
        emit('scrollTimelineTo', newDate);
    }

    function emitPanMode(pEvent) {
        emit('enter-pan-mode', pEvent)
    }

    const dimZoomMenu = ref(false);

    const timeline_position_from_date = (date: Date): number =>
    {
        if (props.to?.getTime() - props.from?.getTime() === 0) {
            return 0;
        }
        return props.width * (date?.getTime() - props.from?.getTime()) / (props.to?.getTime() - props.from?.getTime());
    };

     function getFillColor(bar) {
        if (!bar.color) {
            return '#8ee997';
        }
        if (bar.label == 'Manual') {
            return '#8ee997'; 
        }
        return bar.color;
    }

    function getBarHeight(bar) {
        return bar.height;
    }

    function getBarPoints(barType) {
        if (barType.shape == 'diamond') {
            return '260 10 270 20 280 10 270 0 260 10';   
        }
        if (barType.shape == 'summary') {
            return '150 0 280 0 280 15 273 9 159 9 150 15 150 0';
        }
        if (barType.shape == 'progress') {
            return '152 4 225 4 225 11 152 11 152 4';
        }
        if (barType.shape == 'text') {
            return;
        }
        return '150 0 280 0 280 15 150 15 150 0';    
    }

    function getOutlineColor(bar) {
        if (bar.color == 'transparent' || bar.color == 'white'){
            return 'black';
        }
        if (bar.label == 'Manual') {
            return bar.color; 
        }
        if (bar.border?.color && !bar.border?.isConditional) {
            return bar.border.color
        }
        if (bar.border?.isConditional) {
            return;
        }

        return;
    }

    type TimelineCell = {
        is_first: boolean;
        from_date: Date;
        to_date: Date;
        offset: number;
        width: number;
        timeline_unit: TimelineUnit;
    };

    type Timeline = Array<TimelineCell>;

    enum TimelineUnit {
        Century,
        Decade,
        Year,
        QuarterYear,
        Month,
        Week,
        Day,
        SixHours,
        Hour,
        QuarterHour,
        Minute,
    };

    enum TextType {
        Simple,
        Full,
    }

    const min_cell_width = 40; //px

    const calculate_timeline_unit = (from_date: Date, to_date: Date, timeline_width: number, min_cell_width: number): TimelineUnit => {        
        const daterange_minutes = (to_date?.getTime() - from_date?.getTime()) / 1000 / 60;
        const daterange_quarterhour = daterange_minutes / 15;
        const daterange_hours = daterange_minutes / 60;
        const daterange_sixhours = daterange_hours / 6;
        const daterange_days = daterange_hours / 24;
        const daterange_weeks = daterange_days / 7;
        const daterange_months = daterange_days / 30;
        const daterange_quarteryear = daterange_days / 91;
        const daterange_years = daterange_days / 365;
        const daterange_decades = daterange_years / 10;
        const daterange_centuries = daterange_years / 100;

        if (timeline_width / daterange_minutes >= min_cell_width) {
            return TimelineUnit.Minute;
        } else if (timeline_width / daterange_quarterhour >= min_cell_width) {
            return TimelineUnit.QuarterHour;
        } else if (timeline_width / daterange_hours >= min_cell_width) {
            return TimelineUnit.Hour;
        } else if (timeline_width / daterange_sixhours >= min_cell_width) {
            return TimelineUnit.SixHours;
        } else if (timeline_width / daterange_days >= min_cell_width) {
            return TimelineUnit.Day;
        } else if (timeline_width / daterange_weeks >= min_cell_width) {
            return TimelineUnit.Week;
        } else if (timeline_width / daterange_months >= min_cell_width) {
            return TimelineUnit.Month;
        } else if (timeline_width / daterange_quarteryear >= min_cell_width) {
            return TimelineUnit.QuarterYear;
        } else if (timeline_width / daterange_years >= min_cell_width) {
            return TimelineUnit.Year;
        } else if (timeline_width / daterange_decades >= min_cell_width) {
            return TimelineUnit.Decade;
        } else if (timeline_width / daterange_centuries >= min_cell_width) {
            return TimelineUnit.Century;
        } else {
            return TimelineUnit.Century;
        }
    }
    
    const lower_timeline_unit = computed((): TimelineUnit => calculate_timeline_unit(props.from, props.to, props.width, min_cell_width));
    const upper_timeline_unit = computed(() => Math.max(lower_timeline_unit.value-1, 0));

    const hour = 3600 * 1000;
    const day = hour * 24;

    const three_Hour_hours = Array.from(Array(24/3).keys()).map((i) => (i) * 3);
    const QuarterYear_months = Array.from(Array(12/3).keys()).map((i) => (i) * 3);

    const timeline_width_from_dates = (d1: Date, d2: Date): number => Math.abs(timeline_position_from_date(d1) - timeline_position_from_date(d2));

    const get_next_timeline_date = (date: Date, timeline_unit: TimelineUnit): Date =>
    {
        let new_date: Date;

        switch (timeline_unit)
        {
            case TimelineUnit.Minute:
                new_date = new Date(date?.getTime());
                new_date.setMinutes(date?.getMinutes() + 1, 0, 0);
                break;

            case TimelineUnit.QuarterHour:
                new_date = new Date(date?.getTime());
                new_date.setMinutes(date?.getMinutes() + 15 - (date.getMinutes() % 15), 0, 0);
                break;

            case TimelineUnit.Hour:
                new_date = new Date(date?.getTime());
                new_date.setHours(date?.getHours() + 1, 0, 0, 0);
                break;
            
            case TimelineUnit.SixHours:
                new_date = new Date(date?.getTime());
                new_date.setHours(date?.getHours() + 6 - (date.getHours() % 6), 0, 0, 0);
                break;
            
            case TimelineUnit.Day:
                new_date = new Date(date?.getTime());
                new_date.setDate(new_date?.getDate() + 1);
                new_date.setHours(0, 0, 0, 0);
                break;
            
            case TimelineUnit.Week:
                new_date = new Date(date?.getTime());
                new_date.setDate(new_date?.getDate() + 7 - (new_date.getDay() >= 1 ? new_date.getDay() - 1 : 6));
                new_date.setHours(0, 0, 0, 0);
                break;
            
            case TimelineUnit.Month:
                new_date = new Date(date?.getTime());
                new_date.setMonth(new_date?.getMonth() + 1);
                new_date.setDate(1);
                new_date.setHours(0, 0, 0, 0);
                break;

            case TimelineUnit.QuarterYear:
                new_date = new Date(date?.getTime());
                new_date.setMonth(new_date?.getMonth() + 3 - (new_date.getMonth() % 3));
                new_date.setDate(1);
                new_date.setHours(0, 0, 0, 0);
                break;

            case TimelineUnit.Year:
                new_date = new Date(date?.getTime());
                new_date.setFullYear(new_date?.getFullYear() + 1, 0, 1);
                break;

            case TimelineUnit.Decade:
                new_date = new Date(date?.getTime());
                new_date.setFullYear(new_date?.getFullYear() + 10 - (date.getFullYear() % 10), 0, 1);
                break;

            case TimelineUnit.Century:
                new_date = new Date(date?.getTime());
                new_date.setFullYear(new_date?.getFullYear() + 100 - (date.getFullYear() % 100), 0, 1);
                break;
        }
        
        return new_date;
    }

    const date_text = (timeline_cell: TimelineCell, text_type: TextType): string =>
    {
        switch(timeline_cell.timeline_unit)
        {
            case TimelineUnit.Minute:       return timeline_cell.from_date.toLocaleTimeString(undefined, { hour: 'numeric' });
            case TimelineUnit.QuarterHour:  return timeline_cell.from_date.toLocaleTimeString(undefined, { hour: 'numeric', minute: "numeric" })
            case TimelineUnit.Hour:         return timeline_cell.from_date.toLocaleTimeString(undefined, { hour: '2-digit' }) + ":00";
            case TimelineUnit.SixHours:     return timeline_cell.from_date.toLocaleTimeString(undefined, { hour: '2-digit' }) + ':00 - ' + timeline_cell.to_date.toLocaleTimeString(undefined, { hour: '2-digit' }) + ':00';
            case TimelineUnit.Day:          return timeline_cell.from_date.toLocaleDateString(undefined, { weekday: 'long' });
            case TimelineUnit.Week:         return timeline_cell.from_date.toLocaleDateString(undefined, { month: 'short', day: '2-digit' }) + ' - ' + new Date(new Date(timeline_cell.to_date).setDate(timeline_cell.to_date.getDate() - 1)).toLocaleDateString(undefined, { month: 'short', day: '2-digit' }) + '';
            case TimelineUnit.Month:        return timeline_cell.from_date.toLocaleDateString(undefined, { month: 'long' }) + ' ' + timeline_cell.from_date.getFullYear().toString();
            case TimelineUnit.QuarterYear:  return 'Q' + (Math.floor((timeline_cell.from_date.getMonth()) / 3) + 1) + ' ' + timeline_cell.from_date.getFullYear().toString();
            case TimelineUnit.Year:         return timeline_cell.from_date.getFullYear().toString();
            case TimelineUnit.Decade:       return timeline_cell.from_date.getFullYear().toString();
            case TimelineUnit.Century:      return timeline_cell.from_date.getFullYear().toString();
        }

        return 'N/A';

    };

    const date_text_lower = (timeline_cell: TimelineCell, text_type: TextType): string =>
    {
        switch(timeline_cell.timeline_unit)
        {
            case TimelineUnit.Minute:       return timeline_cell.from_date.toLocaleTimeString(undefined, { minute: 'numeric' });
            case TimelineUnit.QuarterHour:  return timeline_cell.from_date.toLocaleTimeString(undefined, { minute: 'numeric' });
            case TimelineUnit.Hour:         return timeline_cell.from_date.toLocaleTimeString(undefined, { hour: 'numeric' });
            case TimelineUnit.SixHours:     return timeline_cell.from_date.toLocaleTimeString(undefined, { hour: '2-digit' })
            case TimelineUnit.Day:          return timeline_cell.from_date.toLocaleDateString(undefined, { weekday: 'narrow' });
            case TimelineUnit.Week:         return timeline_cell.from_date.toLocaleDateString(undefined, { day: '2-digit' }) + ' - ' + new Date(new Date(timeline_cell.to_date).setDate(timeline_cell.to_date.getDate() - 1)).toLocaleDateString(undefined, {day: '2-digit' }) + '';
            case TimelineUnit.Month:        return timeline_cell.from_date.toLocaleDateString(undefined, { month: 'short' })
            case TimelineUnit.QuarterYear:  return 'Q' + (Math.floor((timeline_cell.from_date.getMonth()) / 3) + 1);
            case TimelineUnit.Year:         return timeline_cell.from_date.getFullYear().toString();
            case TimelineUnit.Decade:       return timeline_cell.from_date.getFullYear().toString();
            case TimelineUnit.Century:      return timeline_cell.from_date.getFullYear().toString();
        }

        return 'N/A';

    };
        

    const create_timeline = (timeline_unit: TimelineUnit, width: number): Timeline =>
    {

        const timeline: Timeline = [];

        let current_offset = 0;
        let current_date = new Date(props.from?.getTime());

        while(current_offset < width) {
            const next_date: Date = get_next_timeline_date(current_date, timeline_unit);
            const next_offset: number = timeline_position_from_date(next_date);
            const current_width: number = next_offset - current_offset;
            
            if (current_width <= 0) {
                break;
            }

            timeline.push({ 
                is_first: (timeline.length == 0),
                from_date: current_date,
                to_date: next_date,
                offset: timeline_unit == upper_timeline_unit.value ? Math.round(current_offset) : current_offset,
                width: timeline_unit == upper_timeline_unit.value ? Math.round(current_width) : current_width,
                timeline_unit: timeline_unit
            });

            current_offset = next_offset;
            current_date = next_date;
        }
        
        return timeline;
    }
    

    const lower_timeline = computed((): Timeline => create_timeline(lower_timeline_unit.value, props.width));
    const upper_timeline = computed((): Timeline => { return upper_timeline_unit.value == lower_timeline_unit.value ? [] : create_timeline(upper_timeline_unit.value, props.width)});

</script>

<style>
    .timeline-header-dropdown:hover{
        -webkit-text-stroke: 0.7px;
    }
</style>
